<template>
  <v-row class="customer-detail custom-border-to">
    <v-col md="12" sm="12" class="pt-0">
      <v-flex class="pa-4">
        <h1
          class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
        >
          Extension
        </h1>
      </v-flex>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="table-tbody"
      >
      </v-skeleton-loader>
      <v-simple-table v-else class="simple-table">
        <template v-slot:default>
          <thead>
            <tr style="background-color: #f3f6f9; color: #24326d">
              <td
                style="width: 60px !important"
                class="font-size-13 text-uppercase font-weight-500"
              >
                Sr.No.
                <!-- Active / In-Active -->
              </td>
              <td
                class="font-size-13 text-uppercase font-weight-500"
                style="width: 60px !important"
              >
                Extension
              </td>
              <!--  <td class="font-size-15 font-weight-600">Last Name</td> -->
              <td
                class="font-size-13 text-uppercase font-weight-500"
                style="width: 200px !important"
              >
                Old Dates
              </td>
              <td
                class="font-size-13 text-uppercase font-weight-500"
                style="width: 200px !important"
              >
                Extended Dates
              </td>
              <td
                class="font-size-13 text-uppercase font-weight-500"
                style="width: 150px !important"
              >
                Extend Period
              </td>
              <td class="font-size-13 text-uppercase font-weight-500">
                Reason
              </td>
              <td
                class="font-size-13 text-uppercase font-weight-500"
                style="width: 150px !important"
              >
                Created At
              </td>
            </tr>
          </thead>
          <tbody>
            <template v-if="rowData?.length > 0">
              <tr
                v-for="(row, index) in rowData"
                :key="index"
                :class="{
                  'custom-border-top': index == 0,
                  'orange_lighten-5': index % 2 == 0,
                }"
              >
                <td class="font-size-14 font-weight-500">
                  {{ index + 1 }}
                </td>
                <td class="font-size-14 font-weight-500 text-capitalize">
                  <v-tooltip right content-class="">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar color="red" size="26" v-bind="attrs" v-on="on">
                        <span
                          class="white--text font-weight-700"
                          v-bind="attrs"
                          v-on="on"
                        >
                          E {{ index + 1 }}</span
                        >
                      </v-avatar>
                    </template>
                    <span> Extension </span>
                  </v-tooltip>
                </td>

                <td class="simple-table-td">
                  <div class="invoice-listing-customer ml-2">
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Start : </b>
                      <template v-if="row?.old_start_date">{{
                        formatDate(row.old_start_date)
                      }}</template>
                      <template v-else
                        ><em class="text--secondary"
                          >No Start Date</em
                        ></template
                      >
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>End : </b>
                      <template v-if="row?.old_end_date">{{
                        formatDate(row.old_end_date)
                      }}</template>
                      <template v-else
                        ><em class="text--secondary">No End Date</em></template
                      >
                    </p>
                  </div>
                </td>
                <td class="simple-table-td">
                  <div class="invoice-listing-customer ml-2">
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Start : </b>
                      <template v-if="row?.extend_start_date">{{
                        formatDate(row.extend_start_date)
                      }}</template>
                      <template v-else
                        ><em class="text--secondary"
                          >No Start Date</em
                        ></template
                      >
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>End : </b>
                      <template v-if="row?.extend_start_date">{{
                        formatDate(row.extend_start_date)
                      }}</template>
                      <template v-else
                        ><em class="text--secondary">No End Date</em></template
                      >
                    </p>
                  </div>
                </td>
                <td class="font-size-14 font-weight-500 text-capitalize">
                  {{ row?.duration }}({{ row.duration_type }})
                </td>
                <td class="simple-table-td">
                  <div class="invoice-listing-customer ml-2 text-capitalize">
                    <template v-if="row && row.description">
                      <span class="d-flex align-baseline">
                        <span
                          class="desc_para"
                          v-html="truncate(row?.description, 60)"
                        ></span>
                        <v-btn
                          small
                          text
                          color="cyan"
                          class="font-weight-700"
                          @click="showDescription(row.description)"
                          >Read More</v-btn
                        >
                      </span>
                    </template>
                    <em v-else class="text-muted"> no reason</em>
                  </div>
                </td>
                <td class="font-size-14 font-weight-500">
                  {{ formatDate(row.added_at) }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="custom-border-top">
                <td colspan="7" class="simple-table-td">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no entesion at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <Dialog :commonDialog="desc_dialog" :dialog-width="800">
      <template v-slot:title> Reason </template>
      <template v-slot:body>
        <v-container fluid>
          <div v-html="fullDescription"></div>
        </v-container>
      </template>

      <template v-slot:action>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="desc_dialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
// import Status from "@/view/pages/partials/Status.vue";
// import Default from "@/view/pages/partials/Default.vue";
import { GET } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";

export default {
  name: "contact-persons",
  title: "Customer",
  mixins: [CommonMixin],
  props: {
    relatedId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rowData: [],
      pageLoading: false,
      fullDescription: null,
      desc_dialog: false,
    };
  },
  components: {
    Dialog,
  },

  methods: {
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    showDescription(description) {
      this.fullDescription = description;
      this.desc_dialog = true;
    },
    getWarrantyExtension() {
      if (this.relatedId && this.relatedId > 0) {
        const _this = this;
        _this.pageLoading = true;
        _this.$store
          .dispatch(GET, { url: `warranty-extension/${this.relatedId}` })
          .then(({ data }) => {
            this.rowData = data;
            _this.pageLoading = false;
          })
          .catch((error) => {
            _this.logError(error);
            _this.pageLoading = false;
          });
      }
    },
  },
  mounted() {
    if (this.relatedId > 0) {
      this.getWarrantyExtension();
    }
  },

  computed: {},
};
</script>
